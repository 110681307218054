import styled from "react-emotion";

const StepsCircleNumber = styled.p`
    padding: 0;
    color: #279AF1;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: 600;
`;

export default StepsCircleNumber;
