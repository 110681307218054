import styled from "react-emotion";

const StepsDescription = styled.p`
    padding: 0;
    color: #000000;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.01em;
    margin-top: 1rem;
    max-width: 250px;

    @media screen and (min-width: 1289px) {
        font-size: 1.125rem;
    }
`;

export default StepsDescription;
