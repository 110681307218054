import styled from "react-emotion";

const StepsCircleText = styled.p`
    padding: 0;
    color: #279AF1;
    font-size: 1rem;
    line-height: 1.125;
`;

export default StepsCircleText;
