import React from "react";
import PropTypes from "prop-types";

import StepsWrap from "./StepsWrap";
import StepsItem from "./StepsItem";
import StepsDescription from "./StepsDescription";
import StepsCircleNumber from "./StepsCircleNumber";
import StepsCircleText from "./StepsCircleText";
import StepsCircle from "./StepsCircle";

const StepsContent = ({ stepsContent }) => {
    return (
        <StepsWrap>
        {stepsContent.map(item => (
            <StepsItem key={item.id}>
                <StepsCircle>
                    <StepsCircleNumber>{item.step}</StepsCircleNumber>
                    <StepsCircleText>шаг</StepsCircleText>
                </StepsCircle>
                <StepsDescription>{item.description}</StepsDescription>
            </StepsItem>
        ))}
    </StepsWrap>
    );
};

export default StepsContent;

StepsContent.propTypes = {
    stepsContent: PropTypes.string.isRequired
};
