import styled from "react-emotion";

const StepsWrap = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        align-items: flex-start;
    }
`;

export default StepsWrap;
