import React from "react";
import styled from "react-emotion";

import Container from "./Container";
import DomenTitle from "./DomenTitle";
import StepsContent from "./StepsContent";

const StepsBlock = () => {
    const stepsData = [
        {
            id: "1",
            step: "1",
            description: "Найдите свободный домен с\u00A0помощью строки поиска"
        },
        {
            id: "2",
            step: "2",
            description: "Зарегистрируйтесь в\u00A0сервисе, указав email"
        },
        {
            id: "3",
            step: "3",
            description: "Заполните данные персоны, пополните баланс сервиса"
        },
        {
            id: "4",
            step: "4",
            description: "Завершите регистрацию, подключите домен к\u00A0сайту"
        }
    ];

    return (
        <Steps>
            <Container>
                <DomenTitle>Четыре шага для подключения домена</DomenTitle>
                <StepsContent stepsContent={ stepsData } />
            </Container>
        </Steps>

    );
};

export default StepsBlock;

const Steps = styled.section`
    background-color: #ffffff;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1600 - 320)));
    width: 100%;
    padding: 3rem 0 1rem;

    @media screen and (min-width: 768px) {
        padding: 3.5rem 0 2rem;
    }

    @media screen and (min-width: 1024px) {
        padding: 4rem 0 3rem;
    }

    @media screen and (min-width: 1280px) {
        padding-top: 4.5rem 0 6rem;
    }
`;
