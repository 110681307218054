import styled from "react-emotion";

const StepsItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    text-align: center;
    position: relative;
    margin-bottom: 2rem;

    @media screen and (min-width: 768px) {
        width: calc(100% / 4 - 15px);
        &::after {
            content: "";
            position: absolute;
            height: 3px;
            top: 52px;
            width: 8.5vw;
            max-width: 165px;
            left: calc(50% + 62px);
            background: url("//s.siteapi.org/prs/ea/ba/12/fbe0ea6071b72d099da346a8c13cfc49.png") repeat;

        }
        &:last-child::after {
            display: none;
        }
    }

    @media screen and (min-width: 1280px){
        &::after {
            width: 15.5vw;
        }
    }
`;

export default StepsItem;
