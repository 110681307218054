import styled from "react-emotion";

const StepsCircle = styled.span`
    width: 6.5em;
    height: 6.5em;
    box-shadow: 0 0.25em 1.5em rgba(114, 128, 170, 0.32);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @media screen and (min-width: 768px) {
        box-shadow: 0 0 3.75em rgba(0, 29, 74, 0.15);
    }
`;

export default StepsCircle;
